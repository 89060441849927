//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { TransactionPermissions } from '@/modules/transaction/transaction-permissions';
// import { i18n } from '@/vueI18n';
import moment from 'moment';

import { TransactionModel } from '@/modules/transaction/transaction-model';
const { fields } = TransactionModel;

export default {
  name: 'app-wallet-transactions-table',

  props:{
    walletId:{
      type: String,
      required: true,
    }
  },

  mounted() {
    this.doFilter();
    // this.doMountTable(this.$refs.table);
  },

  data() {
    return {
      nextPage: 2,
      columns: [
        {
          name: 'icon',
          field: 'type',
          align: 'center',
        },
        {
          name: 'id',
          field: 'id',
          label: 'entities.transaction.fields.id', 
          align: 'center',
        },
        {
          name: 'type',
          field: 'type',
          label: 'entities.transaction.fields.type',
          align: 'center',
        },
        // {
        //   name: 'dueDate',
        //   field: 'dueDate',
        //   label: 'entities.transaction.fields.dueDate',
        //   align: 'center',
        // },
        {
          name: 'description',
          field: 'description',
          label: 'entities.transaction.fields.description',
          align: 'center',
        },
        {
          name: 'amount',
          field: 'amount',
          label: 'entities.transaction.fields.amount',
          align: 'center',
        },
        {
          name: 'paymentMethod',
          field: 'paymentMethod',
          label: 'entities.transaction.fields.paymentMethod',
          align: 'center',
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'entities.transaction.fields.createdAt',
          align: 'center',
        },
        // {
        //   name: 'action',
        //   field: 'action',
        //   label: 'common.action',
        //   align: 'center',
        // },
      ],
    };
  },
  computed: {
    ...mapGetters({
      rows: 'wallet/view/transactions',
      count: 'wallet/view/count',
      loading: 'wallet/view/loadingTransactions',
      pagination: 'wallet/view/pagination',
      // paginationLayout: 'layout/paginationLayout',

      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
    }),
    fields() {
      return fields;
    },
    hasPermissionToEdit() {
      return new TransactionPermissions(this.currentUser).edit;
    },
    tablePagination: {
      get: function() {
        return {
          page: this.pagination.page || 1,
          rowsPerPage: this.pagination.limit || 10,
          sortBy: this.pagination.orderBy || 'createdAt',
          descending: this.pagination.sortBy == 'desc',
          // rowsNumber: xx if getting data from a server
        }
      },

      set: function(value) {
        console.log(value);
      },
    },
    pagesNumber() {
      return Math.ceil(this.rows.length / this.tablePagination.rowsPerPage);
    },
  },
  methods: {
    ...mapActions({
      doFetch: 'wallet/view/doFetchWalletTransactions',
      doChangePaginationCurrentPage: 'wallet/view/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'wallet/view/doChangePaginationPageSize',
      // doMountTable: 'wallet/view/doMountTable',
    }),
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      if (fieldName == 'accountType' || fieldName == 'payerType' || fieldName == 'payeeType' || fieldName == 'paymentMethod' || fieldName == 'status' || fieldName == 'type' || fieldName == 'operation') {
        return this.presenterEnum(row, fieldName)
      }
      const val = TransactionModel.presenter(row, fieldName);
      switch (fieldName) {
        case 'description':
          return val ? val[this.currentLanguageCode] : 'ـــ'
        case 'amount':
          return row['type'] == 'withdraw' 
            ? `${val * -1} ${this.currentLanguageCode == 'ar' ? 'ريال سعودي' : 'SAR'}` 
            : `${val} ${this.currentLanguageCode == 'ar' ? 'ريال سعودي' : 'SAR'}` 
        default:
          return val
      }
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? this.i18n(val[0].i18n) : 'ـــ';
      // return val.length ? val[0].label : 'ـــ';
    },
    presenterMap(row, fieldName) {
      const val = TransactionModel.presenter(row, fieldName);
      return val[this.currentLanguageCode] ? val[this.currentLanguageCode] : 'ـــ'; 
    },
    presenterDateTime(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("DD-MM-YYYY h:mm A") : 'ـــ'; 
    },
    presenterDate(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ'; 
    },
    presenterDay(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("dddd") : 'ـــ'; 
    },
    presenterTime(row, fieldName) {
      const date = row[fieldName]
      return moment(date, "YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.currentLanguageCode).format('hh:mm a')
    },
    presenterOwner(row, fieldName, key) {
      const owner = TransactionModel.presenter(row, fieldName);
      let fieldValue = owner && owner[key] ? owner[key] : null
      let value
      switch (key) {
        case 'name': 
          value = fieldValue && fieldValue[this.currentLanguageCode] ? fieldValue[this.currentLanguageCode] : 'ـــ'
          break
        case 'avatar':
          value = fieldValue
            ? fieldValue
            : 'https://firebasestorage.googleapis.com/v0/b/puricom-beebe.appspot.com/o/public%2Fdefault%2Favatar.svg?alt=media&token=439fb6e6-815f-453e-9e3a-79dc0ab3d40c';
          break
        default:
          value = fieldValue
          break
      }
      return value ? value : 'ـــ';
    },

    async doFilter() {
      return this.doFetch({
        filter: { walletId: this.walletId },
        pagination: { sortBy: 'desc', limit: 10 }
      });
    },
    async doFetchPrevPage() {
      const firstDocument = this.rows[0]
      const pagination = {
        sortBy: 'desc',
        limit: 10,
        doc: firstDocument,
        action: 'prev'
      }
      return this.doFetch({
        filter: { walletId: this.walletId },
        pagination
      });
    },
    async doFetchNextPage() {
      const lastDocument = this.rows[this.rows.length - 1]
      const pagination = {
        sortBy: 'desc',
        limit: 10,
        doc: lastDocument,
        action: 'next'
      }
      return this.doFetch({
        filter: { walletId: this.walletId },
        pagination
      });
    },
  },
};
