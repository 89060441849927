//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/vueI18n';
import moment from 'moment';
// import lodash from 'lodash';

// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore'

export default {
  name: 'app-wallet-card',
  props: {
    icon: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    availableBalance: {
      type: Number,
      required: false,
    },
    'amount-style': {
      type: String,
      default: 'color: #000000 !important;'
    } 
  },

  // firestore() {
  //   return {
  //     user: firebase.firestore().collection('user').doc(this.value.createdBy),
  //   }
  // },

  data() {
    return {
      // user: this.value.payee,
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    amountValue() {
      const amount = this.numberWithCommas(this.amount)
      return this.currentLanguageCode == 'ar' 
        ? `${amount || 0} ريال سعودي`
        : `${amount || 0} SAR`
    },
    availableBalanceValue() {
      const amount = this.numberWithCommas(this.availableBalance)
      return this.currentLanguageCode == 'ar' 
        ? `${amount || 0} ريال سعودي`
        : `${amount || 0} SAR`
    },
    // amountColor() {
    //   return this.value.type == 'credit' 
    //     ? { color: '#23C045' }
    //     : { color: '#F14336' }
    // },
  },
  methods: {
    ...mapActions({

    }),
    i18n(code, args) {
      return i18n(code, args);
    },
    presenterDate(val) {
      return moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY"); 
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
