import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class TransactionPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.transactionRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.transactionImport,
    );
    this.transactionAutocomplete = permissionChecker.match(
      Permissions.values.transactionAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.transactionCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.transactionEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.transactionDestroy,
    );
  }
}
