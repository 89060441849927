import { render, staticRenderFns } from "./wallet-card-loading.vue?vue&type=template&id=4cfc030e&scoped=true&"
import script from "./wallet-card-loading.vue?vue&type=script&lang=js&"
export * from "./wallet-card-loading.vue?vue&type=script&lang=js&"
import style0 from "./wallet-card-loading.vue?vue&type=style&index=0&id=4cfc030e&lang=scss&scoped=true&"
import style1 from "./wallet-card-loading.vue?vue&type=style&index=1&id=4cfc030e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cfc030e",
  null
  
)

export default component.exports
import {QCard,QCardSection,QAvatar,QSkeleton,QCardActions,QBtn} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QAvatar,QSkeleton,QCardActions,QBtn})
