//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import WalletTransactionsTable from '@/modules/wallet/components/wallet-transactions-table.vue';
import WalletCardLoading from '@/modules/wallet/components/wallet-card-loading.vue';
import WalletCard from '@/modules/wallet/components/wallet-card.vue';
import { mapGetters, mapActions } from 'vuex';
// import { i18n } from '@/vueI18n';
// import moment from 'moment';
// import { i18n } from '@/i18n';

import { UserModel } from '@/modules/auth/user-model';
const { fields } = UserModel;

export default {
  name: 'app-wallet-view-page',
  props: ['id'],

  components: {
    [WalletTransactionsTable.name]: WalletTransactionsTable,
    [WalletCard.name]: WalletCard,
    [WalletCardLoading.name]: WalletCardLoading,
  },

  async mounted() {
    this.doFind(this.id)
  },
  data() {
    return {
      model: null,
    };
  },
  computed: {
    ...mapGetters({
      record: 'wallet/view/record',
      loading: 'wallet/view/loading',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    fields() {
      return fields;
    },
    totalDeposit() {
      var total = 0;
      // this.rows.forEach((element) => {
      //   if (element.type == 'deposit') {
      //     total += element.amount;
      //   }
      // });
      return total;
    },
    totalWithdraw() {
      var total = 0;
      // this.rows.forEach((element) => {
      //   if (element.type == 'withdraw') {
      //     total += element.amount;
      //   }
      // });
      return total;
    },
  },
  methods: {
    ...mapActions({
      doFind: 'wallet/view/doFind',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
};
